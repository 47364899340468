//import { youtubeDark, instargramDark, twitterDark, facebookDark } from '../../images';

function Footer() {
  return (
    <>
      {/* <div className="Issuemoa-footer m-5">
        <br/>
        <small className="text-muted">Represented mail : conf312@gmail.com</small>
        <br/>
        <small className="text-muted">Inquiries about partnership : conf312@gmail.com</small>
        <br/>
        <small className="text-muted">Hosting businesses : AWS Korea</small>
        <p className="mt-3">
          <img src={youtubeDark} alt="youtubeDark" className="Cursor-pointer" height="30px;"></img>
          <img src={instargramDark} alt="instargramDark" className="m-3 Cursor-pointer" height="30px;"></img>
          <img src={twitterDark} alt="twitterDark" className="m-3 Cursor-pointer" height="30px;"></img>
          <img src={facebookDark}  alt="facebookDark" className="m-3 Cursor-pointer" height="30px;"></img>
        </p>
        <p><small className="text-muted">Copyright © VNEH Corp. All Rights Reserved.</small></p>
      </div> */}
    </>
  );
}

export default Footer;
